import React from 'react';
import { useQuery } from 'react-query';
import { snakeCase } from 'lodash';
import http from '../../../utils/http';
import { Option } from '../types';
import { useShallowEqualEffect } from '../../../utils/use-shallow-effect';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';

const getDefaultOptionsUrl = (name: string) => `/api/reports/options/${name}`;

const useSelectAdapterOptionsQuery = ({
  id,
  name,
  url,
  dependencies = [],
}: {
  id: string;
  name: string;
  url: string | null;
  dependencies?: string[];
}) => {
  const filters = useFiltersContext();
  const values = filters.values;
  const queryUrl = url || getDefaultOptionsUrl(name);

  const deps = React.useMemo(
    () =>
      Object.fromEntries(
        dependencies.map((key: keyof typeof values) => [
          key,
          values[key] || null,
        ]),
      ),
    [values, dependencies],
  );

  const relatedParams = React.useMemo(
    () =>
      Object.keys(values)
        .filter((key: keyof typeof values) => key !== name)
        .reduce((prev: any, key) => {
          prev[key] = values[key] || null;

          return prev;
        }, {}),
    [values],
  );

  const query = useQuery(
    `report_params_select_options_${name}_${snakeCase(queryUrl)}`,
    async () => {
      let serverOptions = await http.get<never, Option[]>(queryUrl, {
        params: {
          ...deps,
          id,
          params: JSON.stringify(relatedParams),
        },
      });

      return serverOptions.map((item) => ({
        id: String(item.id),
        label: item.name,
      }));
    },
  );

  useShallowEqualEffect(() => {
    query.refetch();
  }, [deps, relatedParams, query.refetch]);

  return query;
};

export default useSelectAdapterOptionsQuery;
