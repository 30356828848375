import React from 'react';
import { Dialog, DialogContent, Theme, Typography } from '@mui/material';
import { Dir } from '../../../../../modules/dirs';
import { DirPermissionsForm } from './DirPermissionsForm';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useSingleDir } from '../../../../../modules/dirs/hooks';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
  dir: Dir;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(3),
      maxHeight: `calc(100% - ${theme.spacing(6)})`,
    },
  },
  content: {
    overflow: 'hidden',
  },
  title: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0,
    },
  },
}));

export const DirPermissionsModal = ({ open, onClose, dir }: Props) => {
  const classes = useStyles();
  const { dir: freshDir } = useSingleDir(dir.id);
  const { t } = useTranslation();
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      aria-labelledby="dir-permissions-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      PaperProps={{
        className: classes.paper,
      }}
    >
      <DialogContent className={classes.content}>
        <ModalClose onClose={onClose} />
        <Typography
          className={classes.title}
          align="center"
          gutterBottom
          variant="h3"
        >
          {t('Directory Permissions')}
        </Typography>
        <DirPermissionsForm dir={freshDir as Dir} />
      </DialogContent>
    </Dialog>
  );
};
