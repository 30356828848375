import React, { useEffect, useMemo } from 'react';
import { FormField as FieldType, FormProps } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ButtonGroup } from '../Button/Button';
import { useTranslation } from 'utils/translation';
import registry from './registry';

const Field: React.FC<FieldType> = (props) => {
  const { name, type, label, rules, ...rest } = props;
  const Component = registry[type] as any;

  if (!Component) {
    throw new Error(`Unsupported element type: ${type}`);
  }

  return <Component {...rest} name={name} label={label} rules={rules} />;
};

const Fields = ({ fields = [] }: { fields: FieldType[] }) =>
  fields.map((field) => <Field {...field} key={field.name} />);

function Form<Values extends object>(
  props: React.PropsWithChildren<FormProps<Values>>,
) {
  const {
    defaultValues,
    errors: defaultErrors = {},
    onBeforeSubmit,
    onSubmit,
    onCancel,
    onChange,
    fields = [],
    slots: { save, cancel } = {},
    children,
  } = props;

  const { t } = useTranslation();
  const methods = useForm<Values>({
    defaultValues,
  });

  const { handleSubmit, formState } = methods;

  const contextValue = useMemo(() => {
    const errors = {
      ...defaultErrors,
      ...formState.errors,
    };

    return {
      ...methods,
      formState: {
        ...formState,
        errors,
      },
    };
  }, [formState, methods, defaultErrors]);

  const values = contextValue.watch();

  useEffect(() => {
    onChange?.(values);
  }, [onChange, values]);

  return (
    <FormProvider {...contextValue}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields?.length > 0 && <Fields fields={fields} />}
        {children}
        <ButtonGroup align="right">
          <Button
            type="submit"
            onClick={onBeforeSubmit}
            isBusy={methods.formState.isSubmitting}
          >
            {save || t('form.submit')}
          </Button>
          {onCancel && (
            <Button onClick={onCancel} variant="text">
              {cancel || t('form.cancel')}
            </Button>
          )}
        </ButtonGroup>
      </form>
    </FormProvider>
  );
}

export default Object.assign(Form, {
  Fields,
  Field,
});
