import React from 'react';
import css from './section.module.scss';
import clsx from 'clsx';
import Typography from '../Typography/Typography';

type Props = {
  titleText?: React.ReactElement | string;
  headerElements?: React.ReactElement[];
  actions?: React.ReactElement[];
  modifier?: 'light-background' | 'card';
  width?: 'exact' | 'fill' | 'full-width';
  padding?: 'default' | 'small';
};

const Section: React.FC<Props & React.HTMLProps<HTMLDivElement>> = (props) => {
  const {
    titleText,
    headerElements,
    actions,
    modifier,
    width = 'full-width',
    padding = 'default',
    children,
    className,
    ...rest
  } = props;

  return (
    <section
      className={clsx(
        css.root,
        css['size-' + width],
        css['padding-' + padding],
        modifier && css[modifier],
        className,
      )}
      {...rest}
    >
      {(titleText || headerElements || actions) && (
        <header className={css.header}>
          {titleText && (
            <Typography
              variant={'h2'}
              className={clsx(
                css.title,
                typeof titleText == 'string' && css['is-string'],
              )}
            >
              {titleText}
            </Typography>
          )}
          {headerElements && headerElements.length > 0 && (
            <ul className={css['header-elements']}>
              {headerElements.map((element, index) => (
                <li key={index} className={css['header-element']}>
                  {element}
                </li>
              ))}
            </ul>
          )}
          {actions && actions.length > 0 && (
            <ul className={css.actions}>
              {actions.map((action, index) => (
                <li key={index} className={css.action}>
                  {action}
                </li>
              ))}
            </ul>
          )}
        </header>
      )}
      {children}
    </section>
  );
};

export default Section;
