import useReport from './use-report';
import { useShallowEqualEffect } from '../../../utils/use-shallow-effect';

const useReportResults = (args: any) => {
  const { id, params, pagination, sortable, queryKeyPrefix } = args;

  const query = useReport({
    page: pagination.page,
    perPage: pagination.perPage,
    sortableField: sortable.field,
    sortableDirection: sortable.direction,
    id,
    params,
    queryKeyPrefix,
  });

  useShallowEqualEffect(() => {
    pagination.setPage(1);
  }, [params]);

  useShallowEqualEffect(() => {
    query.refetch();
  }, [
    query.refetch,
    params,
    sortable.field,
    sortable.direction,
    pagination.page,
    pagination.perPage,
  ]);

  return query;
};

export default useReportResults;
