import ListItemIcon from '@mui/material/ListItemIcon';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { Menu, MenuItem } from '../Menu/Menu';
import { ContextType, ContextState } from './types';
import css from './context-menu.module.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const Context = createContext<ContextType | null>(null);

export const useContextMenuContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error('ContextMenu Context Provider not found.');
  }

  return context;
};

const ContextMenuProvider = ({ children }: React.PropsWithChildren) => {
  const [state, setState] = useState<ContextState | null>(null);

  const contextValue = useMemo(
    () => ({
      state,
      open: setState,
    }),
    [state, setState],
  );

  const onClose = () => {
    setState(null);
  };

  return (
    <Context.Provider value={contextValue}>
      {children}
      {state !== null && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={css.root}
            style={
              {
                ['--offset-x']: `${state.x}px`,
                ['--offset-y']: `${state.y}px`,
              } as React.CSSProperties
            }
          >
            <Menu>
              {Array.from(state.actions || [])
                .filter(({ visible = true }) => visible)
                .map(
                  ({ action, label, disabled, divider, icon: Icon }, index) => (
                    <MenuItem
                      onClick={() => {
                        action && action();
                        onClose();
                      }}
                      key={index}
                      disabled={disabled}
                      divider={divider}
                    >
                      {Icon !== null && <ListItemIcon>{Icon}</ListItemIcon>}
                      {label}
                    </MenuItem>
                  ),
                )}
            </Menu>
          </div>
        </ClickAwayListener>
      )}
    </Context.Provider>
  );
};

export default ContextMenuProvider;
