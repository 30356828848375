import React from 'react';
import { Dir } from '..';
import { useModal } from '../../../components/v2/Modal/useModal';
import FluentForm from '../../../components/v2/Form/FluentForm';
import { patchUpdateDir } from '../service/api';
import Section from '../../../components/v2/Section/Section';
import DirectoryTitleField from './DirectoryTitle';
import { useDirQuery } from '../hooks';
import { useAppFeatures } from '../../ui/selectors/app';
import { useAppSettings } from '../../ui/hooks';
import Loader from '../../../components/v2/Loader';
import get from 'lodash/get';

type Values = {
  title: string | { [lang: string]: string } | any;
};

type Options = {
  onSuccess?(): void;
};

function useRenameDirectory({ id }: Pick<Dir, 'id'>, options: Options = {}) {
  const modal = useModal();

  const onSubmit = ({ title }: Values) => patchUpdateDir({ id, title });

  const Component = () => {
    const { data: dir, isLoading, refetch } = useDirQuery(id);
    const { translatableDirectories } = useAppFeatures();
    const { settings } = useAppSettings();

    const onSuccess = () => {
      modal.close(Component);
      options?.onSuccess && options?.onSuccess();
      void refetch();
    };

    if (isLoading || !dir) {
      return (
        <Section>
          <Loader />
        </Section>
      );
    }

    const defaultTitle = translatableDirectories
      ? Object.fromEntries(
          settings.locales.map((locale: string) => [
            locale,
            get(dir, `translations.${locale}.title`),
          ]),
        )
      : dir.title;

    return (
      <Section>
        <FluentForm<Values>
          onSuccess={onSuccess}
          action={onSubmit}
          defaultValues={{
            title: defaultTitle,
          }}
        >
          <DirectoryTitleField />
        </FluentForm>
      </Section>
    );
  };

  return () => {
    modal.open(Component, { size: 'small' });
  };
}

export default useRenameDirectory;
