import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ModalClose } from '../index';
import { MoveForm } from '../../views/MyDisk/components/Form/Move/MoveForm';
import { useShouldModalBeFullscreen } from '../../utils/media-query';
import { useTranslation } from 'utils/translation';
import { Dir } from '../../modules/dirs';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      height: '70vh',
    },
  }),
);

type Props = {
  onSelect(dirId: Dir['id']): void;
  onClose(): void;
};

const DirectoryPicker: React.FC<Props> = ({ onSelect, onClose }) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();
  const { t } = useTranslation();

  return (
    <Dialog
      aria-labelledby="directory-picker-modal"
      open={true}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm"
    >
      <DialogContent className={classes.content}>
        <ModalClose onClose={onClose} />
        <MoveForm
          title={t('Select directory')}
          onCancel={onClose}
          onSubmit={onSelect}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DirectoryPicker;
