import http from 'utils/http';
import invariant from 'invariant';
import {
  ChangeProfilePasswordPayload,
  CreateUserPayload,
  DeleteUserPayload,
  User,
} from '../index';

export function fetchUsers() {
  return http.get('api/user');
}

export function readUser(user: Pick<User, 'id'>) {
  return http.get(`api/user/${user.id}`);
}

export function createUser(payload: CreateUserPayload) {
  return http.post('api/user', payload);
}

export function updateUser(user: User) {
  invariant(user.id, 'User id is required to perform update call');

  return http.put(`api/user/${user.id}`, user);
}

export function changeUserPassword(user: User, password: string) {
  invariant(user.id, 'User id is required to perform change password call');

  return http.post(`api/user/${user.id}/password`, {
    password,
  });
}

export function patchUpdateUser(user: Partial<User> & Pick<User, 'id'>) {
  invariant(user.id, 'User id is required to perform update call');

  return http.patch(`api/user/${user.id}`, user);
}

export function deleteUser({ id }: DeleteUserPayload) {
  invariant(id, 'User id is required to perform delete call');

  return http.delete(`api/user/${id}`);
}

export function updateProfile({
  firstName,
  lastName,
  email,
  emailAuthEnabled,
}: Partial<User>) {
  return http.put('api/profile/update', {
    firstName,
    lastName,
    email,
    emailAuthEnabled,
  });
}

export function changeProfilePassword({
  currentPassword,
  password,
}: ChangeProfilePasswordPayload) {
  return http.post('api/profile/change-password', {
    currentPassword,
    password,
  });
}

export function fetchUserApiCredentials(user: Pick<User, 'id'>) {
  return http.get(`api/user/${user.id}/client-credentials`);
}

export function createUserApiCredentials(user: Pick<User, 'id'>) {
  return http.post(`api/user/${user.id}/client-credentials`);
}

export function removeUserApiCredentials(
  user: Pick<User, 'id'>,
  clientId: string,
) {
  return http.delete(`api/user/${user.id}/client-credentials/${clientId}`);
}

export function regeneratePasswordLink(user: Pick<User, 'id'>) {
  return http.put(`api/user/${user.id}/password-link`);
}
