import React, { useRef, useState } from 'react';
import { Button, Grid, TextField, Tooltip, Box } from '@mui/material';
import { useTranslation } from '../../utils/translation';

interface Props {
  buttonClassName?: string;
  value: string;
}

const ReadOnlyCopyField: React.FC<Props> = ({ buttonClassName, value }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const handleCopyClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item md={9} lg={10}>
        <Tooltip
          title={t('Copied to clipboard!') as string}
          placement="top-end"
          open={copied}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <TextField
            onClick={handleCopyClipboard}
            inputRef={inputRef}
            inputProps={{
              readOnly: true,
            }}
            variant={'outlined'}
            fullWidth
            value={value}
          />
        </Tooltip>
      </Grid>
      <Grid item md={3} lg={2}>
        <Box ml={1}>
          <Button
            className={buttonClassName}
            variant="outlined"
            color="primary"
            onClick={handleCopyClipboard}
          >
            {t('Copy')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReadOnlyCopyField;
