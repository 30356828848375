import React, { useCallback, useMemo, useState } from 'react';
import { useIntelligenceFields } from 'modules/intelligent-layer/hooks';
import { BasicFieldsForm } from 'modules/intelligent-layer/forms/IntelligenceFieldsForm';
import { useTranslation } from 'utils/translation';
import { useModal } from '../../../../components/v2/Modal/useModal';
import Spacer from '../../../../components/v2/Spacer/Spacer';
import { mapValuesToPayload } from '../../../../modules/intelligent-layer/utils';
import { useCreateNewDevice } from '../hooks';
import Typography from 'components/v2/Typography/Typography';
import Section from 'components/v2/Section/Section';
import Loader from 'components/v2/Loader';
import Button, { ButtonGroup } from 'components/v2/Button/Button';
import Stack from '@mui/material/Stack/Stack';

const defaultRequiredFields = ['serial_number', 'device_type'];

const SuccessNotice = ({
  onCreateNext,
  onClose,
}: {
  onClose(): void;
  onCreateNext(): void;
}) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Stack direction="column" alignItems="center">
        <Typography variant="h2">{t('Device has been created')}</Typography>
        <Spacer size={4} />
        <ButtonGroup>
          <Button onClick={onCreateNext}>{t('Create next device')}</Button>
          <Button onClick={onClose} variant="text">
            {t('Close')}
          </Button>
        </ButtonGroup>
      </Stack>
    </Section>
  );
};

const legacyParseErrors = (error: any) => {
  // TODO:: This should be changed on the server to return
  // correct object with errors
  const message = error?.response?.data?.message || '';

  if (message.toLowerCase().includes('serial')) {
    return {
      serial_number: message,
    };
  }

  if (message.toLowerCase().includes('device type')) {
    return {
      device_type: message,
    };
  }

  return {};
};

type Props = {
  onClose(): void;
  onSuccess?(): void;
  defaultType?: { label: string; value: number } | null;
};

const NewDeviceForm = ({ onClose, onSuccess, defaultType = null }: Props) => {
  const { t } = useTranslation();
  const mutation = useCreateNewDevice();
  const [selectedType, setSelectedType] = useState<any | null>(defaultType);

  const { data = [], isLoading } = useIntelligenceFields([
    'unique-object',
    selectedType?.label?.toLowerCase() || '',
  ]);

  const fields = useMemo(() => {
    return data.map((item) => {
      if (defaultRequiredFields.includes(item.name)) {
        item.settings.required = true;
      }

      return item;
    });
  }, [data]);

  const handleChange = useCallback((values: any) => {
    if (values.hasOwnProperty('device_type')) {
      setSelectedType(values['device_type']);
    }
  }, []);

  const onSubmit = async (values: any) => {
    const payload = mapValuesToPayload(values);

    try {
      await mutation.mutateAsync({
        deviceType: payload.device_type,
        fields: payload,
      });
      onSuccess?.();
    } catch (error) {
      return Promise.reject({
        response: {
          data: {
            errors: legacyParseErrors(error),
          },
        },
      });
    }
  };

  return (
    <>
      <Section>
        <Typography variant="h1">{t('Create new device')}</Typography>
      </Section>
      {isLoading ? (
        <Section>
          <Loader />
        </Section>
      ) : mutation.isSuccess ? (
        <SuccessNotice onCreateNext={mutation.reset} onClose={onClose} />
      ) : (
        <BasicFieldsForm
          key={selectedType?.value || 'unknown'}
          defaultValues={{
            device_type: selectedType,
          }}
          onChange={handleChange}
          fields={fields}
          persister={onSubmit}
        />
      )}
    </>
  );
};

export const useCreateNewDeviceForm = () => {
  const modal = useModal();

  return (
    props: Omit<React.ComponentProps<typeof NewDeviceForm>, 'onClose'> = {},
  ) => {
    const Component = () => <NewDeviceForm {...props} onClose={onClose} />;
    const onClose = () => modal.close(Component);

    modal.open(Component, { onClose, disableBackdropClose: true });
  };
};

export default NewDeviceForm;
