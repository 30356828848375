import React from 'react';
import { File } from '..';
import ActionSheet from 'components/v2/ActionSheet/ActionSheet';
import Icon from '../../../components/v2/Icon/Icon';
import usePermissions from '../../auth/permissions';
import { useBasketActions, useIsFileInBasket } from '../../basket/hooks';
import { useFilePreview } from '../providers/FilePreviewProvider';
import { useTranslation } from 'utils/translation';
import { useFilesActions } from '../hooks';
import useFileShare from '../hooks/use-file-share';
import useConfirmation from 'components/v2/Confirmation/use-confirmation';
import { useDataContext } from 'components/v2/Data/DataContext';
import useRenameFile from '../hooks/useRenameFile';

export const useFileActions = (file: File) => {
  const { t } = useTranslation();
  const confirm = useConfirmation();
  const { can } = usePermissions();
  const { launchPreview } = useFilePreview();
  const onShare = useFileShare(file);
  const filesActions = useFilesActions();
  const dataContext = useDataContext();
  const basketActions = useBasketActions();
  const isInBasket = useIsFileInBasket(file.id);

  const onEdit = useRenameFile(file, {
    onSuccess() {
      dataContext.refresh();
    },
  });

  const onPreview = () => {
    launchPreview(file);
  };

  const onDownload = () => {
    filesActions.download(file);
  };

  const onAddToBasket = () => {
    basketActions.addFile({ file });
  };

  const onRemoveFromBasket = () => {
    basketActions.removeFile({ file });
  };

  const onDelete = async () => {
    const confirmed = await confirm();

    if (confirmed) {
      filesActions.deleteFile(file);
      dataContext.refresh();
    }
  };

  return [
    {
      label: t('Preview'),
      icon: <Icon name="fa-regular fa-eye" />,
      action: onPreview,
    },
    {
      label: t('Download'),
      icon: <Icon name="fa-regular fa-download" />,
      action: onDownload,
    },
    {
      label: t('Share'),
      icon: <Icon name="fa-regular fa-share" />,
      action: onShare,
    },
    {
      label: t('Add To Collection'),
      icon: <Icon name="fa-regular fa-square-plus" />,
      action: onAddToBasket,
      visible: !isInBasket,
    },
    {
      label: t('Remove From Collection'),
      icon: <Icon name="fa-regular fa-times" />,
      action: onRemoveFromBasket,
      visible: isInBasket,
    },
    {
      label: t('Remove'),
      icon: <Icon name="fa-regular fa-trash" />,
      action: onDelete,
      visible: can('delete file'),
    },
    {
      label: t('Edit'),
      icon: <Icon name="fa-regular fa-pencil" />,
      action: onEdit,
      visible: can('update file'),
    },
  ];
};

const FileActions: React.FC<{ file: File }> = ({ file }) => {
  const actions = useFileActions(file);

  return <ActionSheet actions={actions} />;
};

export default FileActions;
