import React, { useEffect } from 'react';
import useHiddenFileInput from '../../../modules/files/hooks/use-hidden-file-input';
import { empty } from 'utils/array';
import bytesToSize from 'utils/bytesToSize';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Stack from '@mui/material/Stack';
import Spacer from '../Spacer/Spacer';

type FileInputValue<Multiple extends boolean> = Multiple extends true
  ? File[]
  : File;

type Props<Multiple extends boolean> = {
  onChange(value: FileInputValue<Multiple>): void;
  multiple?: Multiple;
  disabled?: boolean;
  label?: string;
  accept?: string;
};

const FileInput = <Multiple extends boolean = false>(
  props: Props<Multiple>,
) => {
  const {
    accept,
    multiple = false,
    onChange,
    disabled,
    label = 'Select file',
  } = props;
  const selectFiles = useHiddenFileInput();
  const [files, setFiles] = React.useState<File[]>([]);

  useEffect(() => {
    onChange?.((multiple ? files : files[0]) as FileInputValue<Multiple>);
  }, [files]);

  const onRemove = (file: File) => {
    const nextFiles = Array.from(files).filter((item) => item !== file);

    setFiles(nextFiles);
  };

  const openFilePicker = () => {
    selectFiles({
      accept,
      multiple,
      onChange(event: any) {
        const files = event.target?.files;

        setFiles(multiple ? (prev) => [...prev, ...files] : files);
      },
    });
  };

  return (
    <Stack direction="column" alignItems="flex-start">
      <Button
        disabled={disabled}
        variant="text-primary"
        startIcon={<Icon name="fa-regular fa-upload" />}
        onClick={openFilePicker}
      >
        {label}
      </Button>
      <Spacer />
      <div>
        {!empty(files) && (
          <ul>
            {Array.from(files).map((item, index) => (
              <li key={index}>
                <Button
                  size="xs"
                  variant="text"
                  endIcon={<Icon name="fa-regular fa-times" />}
                  onClick={() => onRemove(item)}
                >
                  {item.name} ({bytesToSize(item.size)})
                </Button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Stack>
  );
};

export default FileInput;
