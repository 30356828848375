import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldArgs, AutocompleteField } from '../types';
import InputLabel from '../../InputLabel/InputLabel';
import { useFormError } from '../utils';
import FormError from '../../FormError/FormError';
import Autocomplete from '../../Autocomplete/Autocomplete';
import get from 'lodash/get';
import FormControl from '../../FormControl/FormControl';

export type AutocompleteAdapterProps = FieldArgs<AutocompleteField>;

const AutocompleteAdapter = (props: AutocompleteAdapterProps) => {
  const { label, name, rules, options, multiple = false } = props;
  const {
    control,
    formState: { errors, defaultValues },
  } = useFormContext();
  const error = useFormError(errors[name]);
  const defaultValue = get(defaultValues, name) || (multiple ? [] : null);

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { value, onChange, disabled } }) => (
          <Autocomplete
            value={value || defaultValue}
            onChange={(event, value) => {
              onChange(value);
            }}
            multiple={multiple}
            options={options}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionKey={(option) => option.value}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <FormError error={error} />
    </FormControl>
  );
};

export default AutocompleteAdapter;
