import React from 'react';
import { Dir } from '../../../../../modules/dirs';
import { Theme, Toolbar } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GroupsManagement } from './GroupsManagement';
import {
  AccessUsersManagement,
  BlockedUsersManagement,
} from './UsersManagement';

interface Props {
  dir: Dir;
}

type TabIndex = 0 | 1 | 2;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    padding: 0,
    minHeight: theme.spacing(4),
    backgroundColor: theme.palette.white,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabLabel: {
    height: theme.spacing(4),
  },
  swipeableView: {
    overflow: 'hidden',
  },
  tabContent: {
    maxHeight: '60vh',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" align="left" {...other}>
      <Box>{children}</Box>
    </Typography>
  );
};

const PermissionsManagement: React.FC<Props> = ({ dir }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<TabIndex>(0);
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue as TabIndex);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index as TabIndex);
  };

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Tabs value={value} variant="standard" onChange={handleChange}>
          <Tab label={t('Groups')} className={classes.tabLabel} />
          <Tab label={t('Users')} className={classes.tabLabel} />
          <Tab label={t('Blocked Users')} className={classes.tabLabel} />
        </Tabs>
      </Toolbar>
      {/* @ts-ignore */}
      <SwipeableViews
        index={value}
        ignoreNativeScroll={false}
        onChangeIndex={handleChangeIndex}
        className={classes.swipeableView}
      >
        <TabPanel value={value} index={0}>
          <GroupsManagement dir={dir} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AccessUsersManagement dir={dir} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BlockedUsersManagement dir={dir} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export { PermissionsManagement };
