import React, { forwardRef } from 'react';
import css from './sidebar.module.scss';

const Sidebar = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ children }, ref) => (
    <aside ref={ref} className={css.root}>
      {children}
    </aside>
  ),
);

const SidebarWrapper = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ children }, ref) => (
  <div ref={ref} className={css.wrapper}>
    {children}
  </div>
));

export { Sidebar, SidebarWrapper };
