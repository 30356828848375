import React, { useState } from 'react';
import css from './cover.module.scss';

export const Cover: React.FC<{ url: string }> = ({ url }) => {
  const [error, setError] = useState(false);
  const fallback = '/images/placeholder.png';

  return (
    <div className={css.root}>
      <img
        className={css.image}
        src={error ? fallback : url || fallback}
        onError={() => setError(true)}
        alt=""
        loading="lazy"
      />
    </div>
  );
};

export default Cover;
