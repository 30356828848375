import { GlobalError } from 'react-hook-form';
import { useTranslation } from 'utils/translation';

export function useFormError(error?: GlobalError | string): string | undefined {
  const { t } = useTranslation();

  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    error.message
  ) {
    return error.message;
  }

  if (error && typeof error === 'object' && 'type' in error && error.type) {
    const type = String(error.type);

    return t(`validation.${type}`);
  }

  if (typeof error === 'string') {
    return error;
  }
}

export function useServerErrors(error: any) {
  if (!error) {
    return undefined;
  }

  const legacy = error?.response.data?.errors?.children;
  if (legacy) {
    let errors = {} as any;

    Object.keys(legacy).map((key) => {
      errors[key] = Array.isArray(legacy[key]?.errors)
        ? legacy[key].errors.at(0)
        : null;
    });

    return errors;
  }

  return error?.response?.data?.errors;
}
