import {
  put,
  call,
  retry,
  PutEffect,
  CallEffect,
  delay,
} from 'redux-saga/effects';
import { get } from 'lodash';
import { Action } from '../actions/action';
import { ResourceRoutine } from '../actions/resource-actions';
import normalizeErrors from '../util/normalize-api-errors';

const defaultResponseMiddleware = (response: any, payload: any) => response;
const defaultErrorMiddleware = (errors: any, payload: any) => {
  const normalized = normalizeErrors(errors);

  if (normalized) {
    return normalized;
  }

  return errors;
};

interface ApiHandlerArgs {
  routine: ResourceRoutine;
  provider: any;
  responseMiddleware?: typeof defaultResponseMiddleware;
  errorMiddleware?: typeof defaultErrorMiddleware;
  maxRetries?: number;
  retryDelay?: number;
}

export const createApiHandler = ({
  routine,
  provider,
  responseMiddleware = defaultResponseMiddleware,
  errorMiddleware = defaultErrorMiddleware,
  maxRetries,
  retryDelay = 100,
}: ApiHandlerArgs) =>
  function* apiHandler(
    action: Action,
  ): Generator<PutEffect<Action> | CallEffect<any>> {
    try {
      yield put(routine.request(action.payload, action.meta));
      const response = maxRetries
        ? yield retry(maxRetries, retryDelay, provider, action.payload)
        : yield call(provider, action.payload);
      const filtered = yield call(responseMiddleware, response, action.payload);

      yield put(routine.success(filtered, action.payload));
    } catch (error: any) {
      const errors = get(error, 'response.data.errors', {});
      const filtered = yield call(errorMiddleware, errors, action.payload);

      yield put(
        routine.failure(filtered, {
          error,
          action,
          response: error?.response,
        }),
      );
    } finally {
      // delay 1 ms for events to pick up the state changes, otherwise the change to fulfill would be immediately
      yield delay(1);
      yield put(routine.fulfill(action.payload, action.meta));
    }
  };
