import React from 'react';
import { useDirCoverUrl } from '../../../modules/dirs/hooks';
import Cover from '../Cover/Cover';

export const DeviceCover: React.FC<{ id: number }> = ({ id }) => {
  const url = useDirCoverUrl(id);

  return <Cover url={url} />;
};

export default DeviceCover;
