import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import TwoFactorAuthCodeForm from '../../../components/v2/Auth/TwoFactorAuthCodeForm';
import { useModal } from '../../../components/v2/Modal/useModal';
import http from '../../../utils/http';
import Listener from '../2fa/listener';
import { resend2faCode } from '../service/api';

const TwoFactorProvider = ({ children }: React.PropsWithChildren) => {
  const modal = useModal();

  useEffect(() => {
    const handler = () => {
      return new Promise<any>((resolve, reject) => {
        const onCancel = () => {
          reject();
          modal.close(Component);
        };

        const onSuccess = (result: any) => {
          resolve(result);
          modal.close(Component);
        };

        const verification = async (code: string) => {
          try {
            return await http.post('/token/2fa_check', {
              _auth_code: code,
            });
          } catch (error: AxiosError | any) {
            return false;
          }
        };

        const Component = () => (
          <TwoFactorAuthCodeForm
            verification={verification}
            onSuccess={onSuccess}
            onCancel={onCancel}
            onResend={resend2faCode}
          />
        );

        modal.open(Component, {
          onClose: () => reject('2FA Canceled'),
        });
      });
    };

    Listener.registerProvider('email', handler);
  }, []);

  return children;
};

export default TwoFactorProvider;
