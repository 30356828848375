import React from 'react';
import { useModal } from 'components/v2/Modal/useModal';
import {
  DirectoryIntelligenceFieldsForm,
  Props,
} from '../forms/IntelligenceFieldsForm';
import { Dir } from '../../dirs';

type Options = Pick<Props, 'onSuccess'> & { enableTitle?: boolean };

export default function useDirectoryIntelligenceFieldsForm(
  id: Dir['id'],
  { onSuccess, enableTitle = false }: Options = {},
) {
  const modal = useModal();

  const Component = () => (
    <DirectoryIntelligenceFieldsForm
      id={id}
      enableTitle={enableTitle}
      onSuccess={() => {
        modal.close(Component);
        onSuccess?.();
      }}
    />
  );

  return () => modal.open(Component);
}
