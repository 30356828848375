import * as React from 'react';
import {
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
  MenuButton as BaseMenuButton,
} from '@mui/base';
import clsx from 'clsx';
import css from './menu.module.scss';

const Menu: React.FC<React.ComponentProps<typeof BaseMenu>> = (props) => (
  <BaseMenu {...props} className={css.menu} />
);

type MenuItemProps = {
  icon?: React.ReactElement | null | undefined;
};

const MenuItem: React.FC<
  MenuItemProps & React.ComponentProps<typeof BaseMenuItem>
> = (props) => {
  const { icon, children } = props;

  return (
    <BaseMenuItem {...props} className={css.item}>
      {icon && <span className={css.icon}>{icon}</span>}
      {children}
    </BaseMenuItem>
  );
};

export type MenuButtonSize = 'small' | 'default' | undefined;

type MenuButtonProps = {
  size?: MenuButtonSize;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
};

const MenuButton: React.FC<
  MenuButtonProps & React.ComponentProps<typeof BaseMenuButton>
> = (props) => {
  const { size, startIcon, endIcon, children, ...rest } = props;

  return (
    <BaseMenuButton
      {...rest}
      className={clsx(
        css.button,
        size && css[`size-${size}`],
        startIcon && !endIcon && !children && css['is-icon-button'],
      )}
    >
      {startIcon && <span className={css.icon}>{startIcon}</span>}
      {children && <span className={css.text}>{children}</span>}
      {endIcon && <span className={css.icon}>{endIcon}</span>}
    </BaseMenuButton>
  );
};

export { Menu, MenuItem, MenuButton };
