import { FormRegistry } from './types';
import TextAdapter from './Adapter/TextAdapter';
import PasswordAdapter from './Adapter/PasswordAdapter';
import EmailAdapter from './Adapter/EmailAdapter';
import SelectAdapter from './Adapter/SelectAdapter';
import DateAdapter from './Adapter/DateAdapter';
import AutocompleteAdapter from './Adapter/AutocompleteAdapter';
import FileAdapter from './Adapter/FileAdapter';
import SelectCreatableAdapter from './Adapter/SelectCreatableAdapter';

const registry: FormRegistry = {
  text: TextAdapter,
  longtext: TextAdapter,
  number: TextAdapter,
  email: EmailAdapter,
  password: PasswordAdapter,
  select: SelectAdapter,
  date: DateAdapter,
  autocomplete: AutocompleteAdapter,
  file: FileAdapter,
  'select-creatable': SelectCreatableAdapter,
} as const;

export default registry;
