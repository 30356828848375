import { useEffect, useRef, useCallback } from 'react';
import { useContextMenuContext } from './ContextMenuProvider';
import { HookArgs } from './types';

const useContextMenu = <Element extends HTMLElement>({ actions }: HookArgs) => {
  const ref = useRef<Element | null>(null);
  const { open } = useContextMenuContext();

  const openContextMenu = useCallback(
    (event: any) => {
      event.preventDefault();

      open({
        x: event.clientX,
        y: event.clientY,
        actions,
      });
    },
    [actions],
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('contextmenu', openContextMenu);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('contextmenu', openContextMenu);
      }
    };
  }, [openContextMenu]);

  return {
    ref,
  };
};

export default useContextMenu;
