import React from 'react';
import Section from '../Section/Section';
import Typography from '../Typography/Typography';
import { Button, ButtonGroup } from '../Button/Button';
import { useTranslation } from 'utils/translation';
import Spacer from '../Spacer/Spacer';

type Props = {
  onConfirm(): void;
  onCancel(): void;
  title?: string;
  question?: string;
  cancelText?: string;
  confirmText?: string;
};

const Confirmation = (props: Props) => {
  const { t } = useTranslation();
  const {
    onConfirm,
    onCancel,
    title = t('Confirmation'),
    question = t('Please confirm your decision'),
    cancelText = t('No, never mind'),
    confirmText = t('Yes, Continue'),
  } = props;

  return (
    <Section>
      <Typography variant="h1" align="center">
        {title}
      </Typography>
      <Spacer size={4} />
      <Typography align="center">{question}</Typography>
      <Spacer size={6} />
      <ButtonGroup align="right">
        <Button onClick={onConfirm}>{confirmText}</Button>
        <Button variant="text" onClick={onCancel}>
          {cancelText}
        </Button>
      </ButtonGroup>
    </Section>
  );
};

export default Confirmation;
