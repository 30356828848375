import React from 'react';
import { File } from '../index';
import FilePreviewModal from '../components/PreviewModal/FilePreviewModal';
import { supportsPreview } from '../utils';

interface PreviewContextType {
  file: Nullable<File>;
  setFile: React.Dispatch<React.SetStateAction<Nullable<File>>>;
}

const PreviewContext = React.createContext<PreviewContextType>({
  file: null,
  setFile: () => {},
});

export const useFilePreview = () => {
  const { setFile } = React.useContext(PreviewContext);

  const launchPreview = (file: File) => {
    if (supportsPreview(file.type)) {
      setFile(file);
    }
  };

  return {
    launchPreview,
  };
};

const FilePreviewProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [file, setFile] = React.useState<Nullable<File>>(null);

  const handleClose = React.useCallback(() => {
    setFile(null);
  }, [setFile]);

  return (
    <PreviewContext.Provider value={{ file, setFile }}>
      {children}
      {file && <FilePreviewModal file={file} onClose={handleClose} />}
    </PreviewContext.Provider>
  );
};

export default FilePreviewProvider;
