import React from 'react';
import { useTranslation } from 'utils/translation';
import Typography from '../Typography/Typography';
import Icon from '../Icon/Icon';
import css from './info.module.scss';
import clsx from 'clsx';

type Props = {
  message?: string;
  type?: 'success' | 'warning' | 'error' | 'info' | 'primary';
};

const Info = ({ message, type }: Props) => (
  <div className={clsx(css.root, type && css[`color-${type}`])}>
    <Icon className={css.icon} name="fa-regular fa-info-circle" />
    <Typography className={css.message}>{message}</Typography>
  </div>
);

const Directory = () => {
  const { t } = useTranslation();

  return <Info message={t('directory_is_empty')} />;
};

export default Object.assign(Info, {
  Directory,
});
