import TableContextProvider from 'components/v2/Table/TableContextProvider';
import React from 'react';
import { useReportContext } from '../../reports/context/ReportContext';

type Props = {
  excluded?: string[];
  keyPrefix?: string;
  itemDetailsAction?(item: any): void;
};

const ReportTableContextProvider = ({
  children,
  excluded = [],
  keyPrefix = '',
  itemDetailsAction,
}: React.PropsWithChildren<Props>) => {
  const { id, enabledAttributes, attributes } = useReportContext();

  const enabled = enabledAttributes.filter((name) => {
    return !excluded.includes(name);
  });

  const available = { ...attributes };
  excluded?.forEach((name) => delete available[name]);

  return (
    <TableContextProvider
      key={id}
      availableAttributes={available}
      attributes={enabled}
      keyPrefix={keyPrefix}
      itemDetailsAction={itemDetailsAction}
    >
      {children}
    </TableContextProvider>
  );
};

export default ReportTableContextProvider;
