import { useTranslation } from 'react-i18next';
import SearchField from '../../components/v2/SearchField/SearchField';
import {
  ChildNavigationItem,
  Navigation,
  NavigationItem,
} from '../../components/v2/Navigation/Navigation';
import Icon from '../../components/v2/Icon/Icon';
import { Sidebar, SidebarWrapper } from '../../components/v2/Sidebar/Sidebar';
import Button from '../../components/v2/Button/Button';
import React, { useCallback, useEffect, useState } from 'react';
import usePermissions from '../../modules/auth/permissions';
import { useBasketActions, useBasketItems } from '../../modules/basket/hooks';
import { useRemoveReportWithConfirmation } from '../../modules/reports/hooks/use-remove-report';
import useReports from '../../modules/reports/hooks/use-reports';
import { Report } from '../../modules/reports/types';
import { useLocation, useNavigate } from 'utils/router';
import debounce from 'lodash/debounce';
import Badge from 'components/v2/Badge/Badge';
import { UpdateReportName } from 'modules/reports/components/SaveReport';
import ReportContextProvider from 'modules/reports/context/ReportContext';
import { encode } from '../../utils/state-encoder';
import { useFiltersQueryParams } from '../../utils/use-data-params';
import { route } from 'router';
import useBasketModal from 'modules/basket/hooks/useBasketModal';
import { useAppSettings } from 'modules/ui/hooks';

const ReportNavItem = ({ report }: { report: Report }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(`/assets/report/${report.id}`);
  const navigate = useNavigate();
  const onRemove = useRemoveReportWithConfirmation(report);
  const [editing, setEditing] = useState(false);

  const actions: any[] = [];

  if (report.isEditable) {
    actions.push({
      label: 'Edit',
      icon: <Icon name="far fa-edit" />,
      action: () => setEditing(true),
    });
  }

  if (report.isRemovable) {
    actions.push({
      label: 'Remove',
      icon: <Icon name="far fa-trash" />,
      action: onRemove,
    });
  }

  const onClick = () => {
    navigate(route('report.index', { id: report.id }));
  };

  return (
    <>
      <ChildNavigationItem
        onClick={onClick}
        title={report.name}
        actions={actions}
        isActive={isActive}
      />
      {/* TODO: Report update form should be created with new Components */}
      {editing && (
        <ReportContextProvider {...report}>
          <UpdateReportName
            reportId={report.id}
            onClose={() => setEditing(false)}
          />
        </ReportContextProvider>
      )}
    </>
  );
};

const SidebarSearch = ({ isActive }: { isActive: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useFiltersQueryParams();
  const [query, setQuery] = useState(params.search || null);

  const onSearchChange = useCallback(
    debounce((search: string) => {
      const filters = encode({ search });
      navigate(`/assets/search?filters=${filters}`);
    }, 500),
    [],
  );

  const onChange = (value: string) => {
    setQuery(value);
    onSearchChange(value);
  };

  return (
    <SearchField
      isActive={isActive}
      value={query}
      placeholder={t('report_search_by_id')}
      size={'large'}
      onChange={onChange}
      expand
    />
  );
};

const BasketButton = () => {
  const { t } = useTranslation();
  const { fetchBasket } = useBasketActions();
  const { files, directories } = useBasketItems();
  const openBasketModal = useBasketModal();
  const itemsCount = files.length + directories.length;

  useEffect(() => {
    fetchBasket();
    // eslint-disable-next-line
  }, []);

  const onClick = () => {
    openBasketModal();
  };

  return (
    <Badge expand badgeContent={itemsCount}>
      <Button
        onClick={onClick}
        startIcon={<Icon name={'fas fa-files'} />}
        expand
      >
        {t('Basket')}
      </Button>
    </Badge>
  );
};

const DashboardSidebar = () => {
  const { can } = usePermissions();
  const { data: reports = [], isLoading } = useReports();
  const navigate = useNavigate();
  const location = useLocation();
  const { settings } = useAppSettings();

  const isActive = (path: string) => {
    return location.pathname.startsWith(`/assets/${path}`);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Sidebar>
      <SidebarSearch isActive={isActive('search')} />
      <Navigation>
        <NavigationItem
          onClick={
            isActive('report')
              ? undefined
              : () => navigate(route('report.index', { id: reports[0]?.id }))
          }
          title={'Assets Lijsten'}
          icon={<Icon name={'far fa-object-subtract'} />}
          isActive={isActive('report')}
        >
          {reports.map((report) => (
            <ReportNavItem report={report} key={report.id} />
          ))}
        </NavigationItem>
        <NavigationItem
          onClick={() => navigate('/assets/types')}
          isActive={isActive('types')}
          title={'Assets Types'}
          icon={<Icon name={'far fa-object-intersect'} />}
        />
        {can('view device groups') && (
          <NavigationItem
            disabled={!settings.features.deviceGroups}
            onClick={() => navigate('/assets/groups')}
            title={'Assets Groepen'}
            isActive={isActive('groups')}
            icon={<Icon name={'far fa-object-union'} />}
          />
        )}
        {can('view service requests') && (
          <NavigationItem
            title={'Service'}
            icon={<Icon name={'far fa-screwdriver-wrench'} />}
            disabled
          />
        )}
        {can('view system log') && (
          <NavigationItem
            title={'Logboek'}
            icon={<Icon name={'far fa-file-lines'} />}
            disabled
          />
        )}
      </Navigation>
      <SidebarWrapper>
        <BasketButton />
      </SidebarWrapper>
    </Sidebar>
  );
};

export default DashboardSidebar;
