import React from 'react';
import classNames from 'classnames';
import css from './spacer.module.scss';

type Props = {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
};

const Spacer: React.FC<Props> = ({ size = 1 }) => (
  <div className={classNames(css.root, css[`size-${size}`])} />
);

export default Spacer;
