import { PopupProps } from '@mui/base/Unstable_Popup';
import React from 'react';
import css from './select.module.scss';
import { Select as SelectBase, SelectRootSlotProps } from '@mui/base/Select';
import { Option as BaseOption } from '@mui/base/Option';
import Icon from '../Icon/Icon';
import clsx from 'clsx';

type Variant = 'default' | 'inline';

type Props = React.ComponentProps<typeof SelectBase> & {
  variant?: Variant;
  slotProps?: {
    popup?: PopupProps;
  };
};

const Button = React.forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
>(
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, children, ...rest } = props;
  return (
    <button type="button" className={css.button} {...rest} ref={ref}>
      <span className={css['button-label']}>{children}</span>
      <Icon className={css['arrow-button']} name={'fas fa-chevron-down'} />
    </button>
  );
});

const Option = ({
  className,
  ...props
}: React.ComponentProps<typeof BaseOption>) => (
  <BaseOption {...props} className={clsx(css.option, className)} />
);

const Select = ({
  children,
  variant = 'default',
  className,
  slotProps: { popup = {} } = {},
  ...props
}: Props) => (
  <SelectBase
    {...props}
    className={clsx(css.root, variant && css[`variant-${variant}`], className)}
    slots={{
      root: Button,
    }}
    slotProps={{
      listbox: { className: css.list },
      popup: {
        ...popup,
        className: css.popup,
        disablePortal: true,
      },
    }}
  >
    {children}
  </SelectBase>
);

export default Object.assign(Select, {
  Option,
});
