import React, { useContext, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const versions = ['default', 'v2'] as const;

type Version = (typeof versions)[number];

type ContextType = {
  version: Version;
  is(version: Version): boolean;
  switchTo(version: Version): void;
};

const UiContext = React.createContext<ContextType | null>(null);

export const useUiVersion = () => {
  const context = useContext(UiContext);

  if (!context) {
    throw new Error('UI Version provider not found!');
  }

  return context;
};

const UiVersionProvider = ({ children }: React.PropsWithChildren) => {
  const [version, setVersion] = useLocalStorageState<Version>(
    '_quodoc_ui_version_',
    {
      defaultValue: versions[0],
    },
  );

  const contextValue = useMemo(
    () => ({
      version,
      is(v: Version) {
        return v === version;
      },
      switchTo: setVersion,
    }),
    [version, setVersion],
  );

  return (
    <UiContext.Provider value={contextValue}>{children}</UiContext.Provider>
  );
};

export default UiVersionProvider;
