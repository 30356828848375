interface DirsConfig {
  types: {
    [key: string]: string;
  };
}

export default {
  types: {
    general: 'General',
    'inherited-type': 'Inherited Type',
  },
} as DirsConfig;
