import React, { useMemo, useRef, useState } from 'react';
import Modal from './Modal';
import ModalContext, { ModalConfig } from './ModalContext';

const ModalProvider = ({ children }: React.PropsWithChildren) => {
  const [stack, setStack] = useState<React.ComponentType[]>([]);
  const extraProps = useRef(new WeakMap());

  const value = useMemo(() => {
    const open = (component: React.ComponentType, props: ModalConfig = {}) => {
      extraProps.current.set(component, props);
      setStack((prev) => [...prev, component]);

      return () => close(component);
    };

    const close = (component: React.ComponentType) => {
      const props = extraProps.current.get(component) || {};
      props.onClose?.();

      extraProps.current.delete(component);
      setStack(stack.filter((item) => item !== component));
    };

    return {
      open,
      close,
    };
  }, [stack, setStack]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {stack.length > 0 &&
        stack.map((Component, index) => {
          const props = extraProps.current.get(Component) || {};

          return (
            <Modal
              key={index}
              {...props}
              open
              onClose={() => value.close(Component)}
            >
              <Modal.Content>
                <Component />
              </Modal.Content>
            </Modal>
          );
        })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
