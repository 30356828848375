import React from 'react';
import css from './modal.module.scss';
import { Modal as BaseModal } from '@mui/base';
import { ModalBackdropSlotProps } from '@mui/base/Modal/Modal.types';
import clsx from 'clsx';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

type Props = {
  size?: 'tiny' | 'small' | 'default';
  disableClose?: boolean;
  disableBackdropClose?: boolean;
};

const Content = ({ children }: React.PropsWithChildren) => (
  <div className={css.content}>{children}</div>
);

const Backdrop = React.forwardRef<HTMLDivElement, ModalBackdropSlotProps>(
  (props, ref) => {
    const { open, ownerState, ...other } = props;

    return <div ref={ref} {...other} />;
  },
);

export type ModalProps = Props & React.ComponentProps<typeof BaseModal>;

const Modal = ({
  children,
  size = 'default',
  disableClose = false,
  disableBackdropClose = false,
  onClose,
  ...props
}: ModalProps) => {
  const handleClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown',
  ) => {
    if (disableBackdropClose && reason === 'backdropClick') {
      return;
    }

    onClose?.(event, reason);
  };

  return (
    <BaseModal
      {...props}
      onClose={handleClose}
      className={css.root}
      slotProps={{
        root: {
          className: clsx(css.root, css['size-' + size]),
        },
        backdrop: {
          className: css.backdrop,
        },
      }}
      slots={{
        backdrop: Backdrop,
      }}
    >
      <div className={css.modal}>
        {!disableClose && (
          <Button
            onClick={onClose}
            className={css['close-button']}
            startIcon={<Icon name={'fas fa-times'} />}
            variant={'text'}
          />
        )}
        {children}
      </div>
    </BaseModal>
  );
};

export default Object.assign(Modal, {
  Content,
});
