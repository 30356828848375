import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/v2/Button/Button';
import useContextBar from '../../../components/v2/ContextBar/useContextBar';
import { useFiltersContext } from '../../../components/v2/Filters/FiltersContext';
import Icon from '../../../components/v2/Icon/Icon';
import { useCreateNewDeviceForm } from 'plugins/device/src/components/NewDeviceForm';
import usePermissions from '../../auth/permissions';
import Filters from '../components/Filters';
import { ReportTableActions } from '../components/ReportTableActions';
import Results from '../components/Results';
import ReportProvider from '../providers/ReportProvider';
import Page from 'components/v2/Page/Page';
import { useReportContext } from '../../reports/context/ReportContext';
import Section from 'components/v2/Section/Section';
import { useTranslation } from 'utils/translation';
import ContextBar from 'components/v2/ContextBar/ContextBar';
import ReportTableContextProvider from '../providers/ReportTableContextProvider';
import { DeviceDetailsContextBar } from '../components/DeviceDetailsContextBar';

const ReportView = () => {
  const { t } = useTranslation();
  const { can } = usePermissions();
  const { name } = useReportContext();
  const { hasActiveFilters } = useFiltersContext();
  const openNewDeviceForm = useCreateNewDeviceForm();
  const headerElements = [];

  if (can('create directory')) {
    headerElements.push(
      <Button
        onClick={openNewDeviceForm}
        variant="text-primary"
        size={'large'}
        startIcon={<Icon name="fa-regular fa-square-plus" />}
      />,
    );
  }

  return (
    <Page title={name}>
      <Section
        titleText={
          <span>
            {hasActiveFilters() ? `${t('Filter')}: ` : ''}
            <strong>{name}</strong>
          </span>
        }
      >
        <Filters />
      </Section>
      <Section
        titleText={t('Devices')}
        modifier={'light-background'}
        headerElements={headerElements}
        actions={[<ReportTableActions />]}
      >
        <Results />
      </Section>
    </Page>
  );
};

export default () => {
  const { i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const contextBar = useContextBar();

  return (
    <ReportProvider id={id!} key={`${id!}_${i18n.language}`}>
      <ReportTableContextProvider
        itemDetailsAction={(item) => {
          contextBar.open({
            title: `ID: ${item.title}`,
            icon: null,
            component: () => <DeviceDetailsContextBar id={item.id} />,
          });
        }}
      >
        <ReportView />
        <ContextBar />
      </ReportTableContextProvider>
    </ReportProvider>
  );
};
