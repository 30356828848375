import { useImperativeHandle } from 'react';
import * as React from 'react';
import { useTranslation } from 'utils/translation';
import SelectAdapter from './Adapter/SelectAdapter';
import DateAdapter from './Adapter/DateAdapter';
import Button from '../Button/Button';
import OptionsButton from '../Button/OptionsButton';
import Icon from '../Icon/Icon';
import css from './filters.module.scss';
import clsx from 'clsx';
import { AdapterProps, SelectAdapterProps } from './types';
import { BaseValues, useFiltersContext } from './FiltersContext';

type AbstractFilter = {
  name: string;
  label: string;
};

type SelectFilter = AbstractFilter &
  SelectAdapterProps & {
    type: 'select';
  };

type DateRangeFilter = AbstractFilter & {
  type: 'date-range';
  defaultValue?: number[];
};

type DateFilter = AbstractFilter & {
  type: 'date';
  defaultValue?: number;
};

type Filter = SelectFilter | DateRangeFilter | DateFilter;
export type FilterType = 'select' | 'date' | 'date-range';

type ComponentMap = {
  select: React.FunctionComponent<
    Omit<SelectFilter, 'type'> & SelectAdapterProps
  >;
  date: React.FunctionComponent<Omit<DateFilter, 'type'>>;
  'date-range': React.FunctionComponent<Omit<DateRangeFilter, 'type'>>;
};

const map: {
  [Key in FilterType]: ComponentMap[Key];
} = {
  // @ts-ignore
  select: SelectAdapter,
  date: DateAdapter,
  // 'date-range': DateRangeAdapter,
};

const SingleFilter: React.FC<Filter & AdapterProps> = ({ type, ...rest }) => {
  const Component = map[type] as any;

  return <Component {...rest} />;
};

export type Controller = {
  getActiveFilters(): Filter[];
  getAvailableFilters(): Filter[];
};

type Props<Values extends BaseValues = {}> = {
  items: Filter[];
  defaultEnabled?: string[];
  isLoading?: boolean;
  totalCount?: number;
  onSubmit?(values: Values): void;
  onSave?(): void;
  controllerRef?: React.MutableRefObject<Controller | undefined>;
};

const Filters: React.FC<Props> = ({
  items,
  isLoading = false,
  totalCount,
  onSubmit,
  onSave,
  controllerRef,
  defaultEnabled = [],
}) => {
  const { t } = useTranslation();
  const filters = useFiltersContext();
  const possibleItems = items.filter((i) => !!map[i.type]);
  const [enabled, setEnabled] = React.useState(defaultEnabled);
  const available = possibleItems.filter((i) => !enabled.includes(i.name));
  const active = enabled
    .map((name) => possibleItems.find((i) => i.name === name)!)
    .filter(Boolean);
  useImperativeHandle(controllerRef, () => ({
    getActiveFilters: () => active,
    getAvailableFilters: () => available,
  }));

  return (
    <div className={css.filters}>
      <div className={css.list}>
        {active.map((item) => (
          <div key={item.name} className={clsx(css.item, css[item.type])}>
            <SingleFilter
              value={filters.values[item.name]}
              onChange={(value) => filters.setValue(item.name, value)}
              onRemove={() => {
                filters.remove(item.name);
                setEnabled((prev) => prev.filter((i) => i !== item.name));
              }}
              {...item}
            />
          </div>
        ))}
        {available.length > 0 && (
          <div className={css['add-filter']}>
            <OptionsButton
              startIcon={<Icon name={'far fa-circle-plus'} />}
              variant={'text'}
              actions={available.map((filter) => ({
                label: filter.label,
                action() {
                  setEnabled((prev) => [...prev, filter.name]);
                },
              }))}
            >
              {t('filter.add_filter')}
            </OptionsButton>
          </div>
        )}
      </div>
      <div className={css.buttons}>
        <Button
          onClick={() => onSubmit && onSubmit(filters.values)}
          disabled={isLoading}
          endIcon={
            isLoading ? (
              <Icon name="fa-duotone fa-spinner fa-spin" />
            ) : Number(totalCount) > 0 ? (
              `(${totalCount})`
            ) : null
          }
        >
          {t('filter.show_result')}
        </Button>
        <Button
          disabled={Object.keys(filters.values).length === 0}
          onClick={onSave}
          variant={'text'}
          size={'small'}
        >
          {t('report_save_filter_settings')}
        </Button>
      </div>
    </div>
  );
};

export default Filters;
