import { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import i18n from '../../utils/translation';
import {
  selectRootDirs,
  selectDir,
  isDirFetching,
  selectIsFetching,
  selectDirSiblings,
  selectFormState,
  selectDirChildren,
  selectLoadedDirs,
  selectIsRootDirsLoading,
  isDirDownloading,
} from './selectors';
import { dirsActions } from './actions';
import { Dir, CreateDirPayload, MoveDirPayload } from './index';
import { RootState } from '../../store';
import { queryCache, QueryConfig, useMutation, useQuery } from 'react-query';
import http from '../../utils/http';
import { readDir, fetchDirs, getCover, searchDirs } from './service/api';
import snakeCase from 'lodash/snakeCase';
import { useTranslation } from 'utils/translation';

export function useDirsActions() {
  const dispatch = useDispatch();
  const fetchDirs = (params: object = {}, meta: object = {}) =>
    dispatch(dirsActions.fetch.trigger(params, meta));
  const preloadDirs = () => dispatch(dirsActions.preload.trigger());
  const createDir = (data: CreateDirPayload) =>
    dispatch(dirsActions.create.trigger(data));
  const readDir = (data: Pick<Dir, 'id'>, meta: object = {}) =>
    dispatch(dirsActions.read.trigger(data, meta));
  const updateDir = (dir: Pick<Dir, 'id'> & Partial<Dir>) =>
    dispatch(dirsActions.update.trigger(dir));
  const pathUpdateDir = (
    dir: Pick<Dir, 'id'> & (Partial<Dir> | { title: string | object }),
  ) => dispatch(dirsActions.patchUpdate.trigger(dir));
  const moveDir = (dir: MoveDirPayload) =>
    dispatch(dirsActions.move.trigger(dir));
  const deleteDir = (dir: Dir) => dispatch(dirsActions.delete.trigger(dir));
  const clearErrors = () => dispatch(dirsActions.clearErrors());
  const downloadDir = (dir: Dir) => dispatch(dirsActions.download.trigger(dir));
  const reset = () => dispatch(dirsActions.reset.trigger());

  return useMemo(
    () => ({
      fetchDirs,
      preloadDirs,
      createDir,
      readDir,
      updateDir,
      pathUpdateDir,
      moveDir,
      deleteDir,
      downloadDir,
      clearErrors,
      reset,
    }),
    // eslint-disable-next-line
    [dispatch],
  );
}

export function useSingleDir(id: number) {
  const dir = useSelector(
    (state) => selectDir(state as RootState, id),
    shallowEqual,
  ) as Nullable<Dir>;
  const isFetching = useSelector(
    (state) => isDirFetching(state as RootState, id),
    shallowEqual,
  );

  return {
    dir,
    isFetching,
  };
}

export function useDirQuery(
  id: number | string,
  queryConfig: QueryConfig<Dir> = {},
) {
  const { i18n } = useTranslation();

  return useQuery<Dir>(
    `api_directory_${id}_${i18n.language}`,
    () => readDir({ id: Number(id) }),
    queryConfig,
  );
}

export const refetchDirQuery = ({ id }: { id: number }) => {
  return queryCache.refetchQueries(`api_directory_${id}_${i18n.language}`);
};

export function useDirCoverUrl(id: number, queryConfig: any = {}) {
  const { data = [], isLoading } = useDirCoverQuery(id);

  return data[0]?.previewUrl!;
}

export function useDirCoverQuery(id: number, queryConfig: any = {}) {
  return useQuery(
    `api_directory_cover_${id}`,
    () => getCover({ id: Number(id) }),
    queryConfig,
  );
}

export function useDirsQuery(params: object = {}) {
  return useQuery(
    `api_directories_query-${snakeCase(JSON.stringify(params))}`,
    () => fetchDirs(params),
  );
}

export function useDirsSearch(params: object) {
  return useQuery('api_directories_query_search', () => searchDirs(params));
}

export function useDirs(dirId?: number) {
  const dirsSelector = dirId
    ? (state: RootState) => selectDirChildren(state as RootState, dirId)
    : selectRootDirs;

  const dirs = useSelector(dirsSelector, shallowEqual);
  const isFetching = useSelector(selectIsFetching, shallowEqual);
  const isRootDirsFetching = useSelector(selectIsRootDirsLoading, shallowEqual);

  return {
    dirs,
    isFetching: isFetching || isRootDirsFetching,
  };
}

export function useDirSiblings(id: number): Dir[] {
  return useSelector(
    (state: RootState) => selectDirSiblings(state, id),
    shallowEqual,
  );
}

export function useDirsFormState() {
  return useSelector(selectFormState, shallowEqual);
}

export function useLoadedDirs() {
  const dirs = useSelector(selectLoadedDirs, shallowEqual) as Dir[];
  const isLoading = useSelector(selectIsRootDirsLoading, shallowEqual);

  return {
    dirs,
    isLoading,
  };
}

export function useIsDirDownloading() {
  return useSelector(isDirDownloading, shallowEqual);
}

export interface CreateNewObjectPayload {
  title: string;
  cover?: File;
  fields: {
    [key: string]: any;
  };
}

export function useCreateNewObjectType() {
  return useMutation<Dir, never, CreateNewObjectPayload>((payload) => {
    const formData = new FormData();
    formData.append('title', payload.title);

    if (payload.cover) {
      formData.append('cover', payload.cover);
    }

    return http.post('/api/directory/object-type', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });
}

export interface CreateNewObjectMultiLangPayload {
  title: {
    [key: string]: string;
  };
  cover?: File;
  fields: {
    [key: string]: any;
  };
}

export function useCreateNewObjectTypeMultiLang() {
  return useMutation<Dir, never, CreateNewObjectMultiLangPayload>((payload) => {
    const formData = new FormData();
    Object.keys(payload.title).map((key) => {
      formData.append(`title_${key}`, payload.title[key]);
    });

    if (payload.cover) {
      formData.append('cover', payload.cover);
    }

    return http.post('/api/directory/object-type', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  });
}
