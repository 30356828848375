import React from 'react';
import HighlightText from '../../../components/Highlight/HighlightText';
import { useSearchContext } from '../../../components/v2/SearchField/SearchContext';

const HightLightQuery = ({ value }: { value: string }) => {
  const { query } = useSearchContext();

  if (!query || typeof value !== 'string') {
    return value;
  }

  return <HighlightText text={value} highlight={query} />;
};

export default HightLightQuery;
