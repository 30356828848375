import {
  takeLatest,
  takeEvery,
  select,
  fork,
  SelectEffect,
} from '@redux-saga/core/effects';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import { refetchDirQuery } from '../dirs/hooks';
import { filesActions } from './actions';
import {
  fetch,
  read,
  updateFile,
  deleteFile,
  getThumb,
  getPreview,
  moveFile,
  shareFile,
  abortUpload,
} from './service/api';
import { transform } from '../../utils/transform-entities';
import { File, QueuedFile } from '.';
import { selectFile } from './selectors';
import { RootState } from '../../store';
import { handleFileUpload } from './saga/file-upload';
import { Action } from '../@common/actions/action';
import {
  actionChannel,
  take,
  call,
  put,
  CallEffect,
  TakeEffect,
  PutEffect,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { downloadWithAuth } from '../@common/sagas/file-downloader';

const handleFilesFetch = createApiHandler({
  routine: filesActions.fetch,
  provider: fetch,
  responseMiddleware: (response, payload) => ({
    items: transform(response.data),
    order: response.data.map((file: File) => file.id),
    isRoot: !payload.directory,
  }),
});

const handleFileRead = createApiHandler({
  routine: filesActions.read,
  provider: read,
});

const handleFileUpdate = createApiHandler({
  routine: filesActions.update,
  provider: updateFile,
});

const handleFileDelete = createApiHandler({
  routine: filesActions.delete,
  provider: deleteFile,
});

const handleFetchFileThumb = createApiHandler({
  routine: filesActions.getThumb,
  provider: getThumb,
});

function* handleThumbFetch(
  action: any,
): Generator<SelectEffect | CallEffect | Generator> {
  const { id } = action.payload;
  const file = yield select((state: RootState) => selectFile(state, id));

  if (file && (file as File)?.thumbnailUrl) {
    return;
  }

  yield handleFetchFileThumb(action);
}

function* watchFetchThumbs(): Generator {
  const channel = yield actionChannel(filesActions.getThumb.TRIGGER);

  while (true) {
    const action = yield take(channel as any);
    yield call(handleThumbFetch, action);
  }
}

const handleFetchFilePreview = createApiHandler({
  routine: filesActions.getPreview,
  provider: getPreview,
});

function* handlePreviewUrlFetch(action: any): Generator {
  const { id } = action.payload;
  const file = yield select((state: RootState) => selectFile(state, id));

  if (file && (file as File).previewUrl) {
    return;
  }

  yield handleFetchFilePreview(action);
}

const handleMoveFile = createApiHandler({
  routine: filesActions.move,
  provider: moveFile,
});

function* handleFileCreate(action: Action): Generator {
  const { file, dirId } = action.payload;
  const uploadedFile = yield select((state) => state.upload.items[file.id]);

  if (uploadedFile) {
    yield call(handleFileUpload, file, dirId);
  }
}

function* fileUploadWorker(channel: any): Generator<TakeEffect | CallEffect> {
  while (true) {
    const action = yield take(channel);
    // @ts-ignore
    yield call(handleFileCreate, action);
  }
}

function* watchFileCreate(): Generator<any> {
  const queuedChannel = yield call(channel);

  // Limit parallel upload requests
  for (let i = 1; i <= 3; i++) {
    yield fork(fileUploadWorker, queuedChannel);
  }

  while (true) {
    const action = yield take(filesActions.create.TRIGGER);
    // @ts-ignore
    yield put(queuedChannel, action);
  }
}

function* handleFileUploadCancel(action: Action<QueuedFile>) {
  const { id } = action.payload;

  yield call(abortUpload, { id });
}

function* handleFileUploadCancelAll(): Generator {
  const items = yield select((state) => state.upload.items);
  const ids = Object.keys(items as any[]);
  const actions = ids.map((id) => filesActions.cancelUpload.trigger({ id }));

  for (const action of actions) {
    yield put(action);
  }
}

function* handleFileDownload(action: Action) {
  const { id } = action.payload;
  const url = `/api/file/download/${id}`;

  try {
    yield call(downloadWithAuth, url);
  } catch (e) {
    yield put(filesActions.download.failure(action.payload, action.meta));
  } finally {
    yield put(filesActions.download.fulfill());
  }
}

const fileShareHandler = createApiHandler({
  routine: filesActions.share,
  provider: shareFile,
});

function* handleFileShare(action: any): Generator {
  const { id } = action.payload;
  const file = yield select((state) => selectFile(state, id));

  if (file && (file as File).shareUrl) {
    return;
  }

  yield fileShareHandler(action);
}

function* handleFileCreateSuccess(action: any) {
  const id = action?.payload?.directory?.id;

  if (id) {
    yield call(refetchDirQuery, { id });
  }
}

export default function* () {
  yield takeLatest(filesActions.fetch.TRIGGER, handleFilesFetch);
  yield takeEvery(filesActions.read.TRIGGER, handleFileRead);
  //yield takeEvery(filesActions.create.TRIGGER, handleFileCreate);
  yield takeEvery(filesActions.update.TRIGGER, handleFileUpdate);
  yield takeEvery(filesActions.delete.TRIGGER, handleFileDelete);
  yield takeEvery(filesActions.move.TRIGGER, handleMoveFile);
  yield takeEvery(filesActions.download.TRIGGER, handleFileDownload);
  yield takeEvery(filesActions.share.TRIGGER, handleFileShare);
  yield takeEvery(filesActions.getPreview.TRIGGER, handlePreviewUrlFetch);
  yield takeEvery(filesActions.cancelUpload.TRIGGER, handleFileUploadCancel);
  yield takeEvery(filesActions.create.SUCCESS, handleFileCreateSuccess);
  yield takeEvery(
    filesActions.cancelUploadAll.TRIGGER,
    handleFileUploadCancelAll,
  );

  yield fork(watchFileCreate);
  yield fork(watchFetchThumbs);
}
