import { useQuery, useMutation } from '@tanstack/react-query';
import http from '../../../utils/http';
import { Dir } from '../../../modules/dirs';
import {
  FeedbackArgs,
  MaintenanceRequestArgs,
  postSubmitFeedback,
  postSubmitMaintenanceRequest,
} from './api';
import './translations';

export function useDeviceTypes() {
  return useQuery({
    queryKey: ['app_device_types'],
    queryFn: () => http.get('/api/device-types') as unknown as any[],
  });
}

export function useCreateNewDevice() {
  return useMutation<any, any, any>({
    // TODO:: Update the URL when API changes released
    mutationFn: (payload) => http.post('/api/ardamedical/device', payload),
  });
}

type InfoResponse = {
  cover?: string;
  fields: {
    [key: string]: string;
  };
  commonIssues?: string[];
  [key: string]: string | any;
};

export function useFeedbackItemInfo(id: Dir['id']) {
  return useQuery({
    queryKey: [`app_device_feedback_item_info_${id}`],
    queryFn: () =>
      http.get<unknown, InfoResponse>(`/api/feedback-channel/info/${id}`),
  });
}

export function useSubmitFeedback(id: Dir['id']) {
  return useMutation({
    mutationFn: (data: FeedbackArgs) => {
      return postSubmitFeedback(id, data);
    },
  });
}

export function useSubmitMaintenanceRequest(id: Dir['id']) {
  return useMutation({
    mutationFn: (data: MaintenanceRequestArgs) => {
      return postSubmitMaintenanceRequest(id, data);
    },
  });
}
