import React, { useEffect } from 'react';
import { useLocation } from 'utils/router';
import css from './dashboard.module.scss';
import ContextBarProvider from '../ContextBar/ContextBarProvider';
import clsx from 'clsx';

type Props = React.PropsWithChildren<{
  topBar?: React.ReactElement;
  sidebar?: React.ReactElement;
}>;

const Dashboard: React.FC<Props> = ({ topBar, sidebar, children }) => (
  <div className={css.root}>
    <header className={css.header}>{topBar && <div>{topBar}</div>}</header>
    <main className={clsx(css.main)}>
      {sidebar && <div className={css.sidebar}>{sidebar}</div>}
      {children && (
        <div className={clsx(css.content)}>
          <div className={css.children}>{children}</div>
        </div>
      )}
    </main>
  </div>
);

export default (props: Props) => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.classList.add('disable-scroll');

    return () => document.documentElement.classList.remove('disable-scroll');
  }, []);

  return (
    <ContextBarProvider key={location.pathname}>
      <Dashboard {...props} />
    </ContextBarProvider>
  );
};
