import React, { ComponentProps, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  FormControl,
  TextField,
  Box,
  Dialog,
  DialogContent,
  Button,
  IconButton,
} from '@mui/material';
import { ModalClose } from '../../../components';
import { refetchReport } from '../hooks/use-report-spec';
import { refetchReports } from '../hooks/use-reports';
import useSaveReport, { useUpdateReport } from '../hooks/use-save-report';
import ReportContextProvider, {
  useReportContext,
} from '../context/ReportContext';
import useAutoFocus from '../../../utils/use-auto-focus';
import EditIcon from '@mui/icons-material/Edit';
import { Report } from '../types';

type Values = {
  name: string;
};

const SaveReportForm: React.VFC<{
  onSave(name: string): void;
  isBusy: boolean;
}> = ({ onSave, isBusy }) => {
  const { name: defaultName, isEditable } = useReportContext();
  const focusRef = useAutoFocus();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Values>({
    defaultValues: {
      name: isEditable ? defaultName : '',
    },
  });

  const onSubmit: SubmitHandler<Values> = (values) => {
    onSave(values.name);
  };

  const inputProps = {
    ...register('name', { required: true }),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ pt: 4, px: 2, display: 'flex', flexDirection: 'column' }}>
        <FormControl error={!!errors.name}>
          <TextField
            autoComplete="off"
            error={!!errors.name}
            sx={{ minWidth: 300 }}
            label="Name"
            inputProps={{
              ...inputProps,
              ref: (node: HTMLInputElement) => {
                inputProps.ref(node);
                focusRef(node);
              },
            }}
          />
        </FormControl>
        <FormControl sx={{ py: 2 }}>
          <Button
            disabled={isBusy}
            size="large"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </FormControl>
      </Box>
    </form>
  );
};

const SaveReport = ({
  onClose,
  filters,
  active = [],
  reportId,
  hook: useSaveMutation = useSaveReport,
}: any) => {
  const [mutate, result] = useSaveMutation();

  const handleSave = async (name: string) => {
    await mutate({
      id: reportId,
      name,
      filters,
      active: Array.from(active).map((item: any) => item.name),
    });
    void refetchReports();
    void refetchReport({ id: reportId });
    onClose();
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogContent>
        <ModalClose onClose={onClose} />
        <SaveReportForm onSave={handleSave} isBusy={result.isLoading} />
      </DialogContent>
    </Dialog>
  );
};

export const UpdateReportName = (props: {
  reportId: Report['id'];
  onClose(): void;
}) => {
  return <SaveReport {...props} hook={useUpdateReport} />;
};

const DefaultButton: React.FC<ComponentProps<typeof IconButton>> = (props) => (
  <IconButton size="small" {...props}>
    <EditIcon fontSize="inherit" />
  </IconButton>
);

export const UpdateReportNameButton: React.VFC<{
  report: Report;
  component?: React.ComponentType;
}> = ({ component: Component = DefaultButton, report }) => {
  const [active, setActive] = useState(false);

  return (
    <ReportContextProvider {...report}>
      <>
        <Component onClick={() => setActive(true)} />
        {active && (
          <UpdateReportName
            reportId={report.id}
            onClose={() => setActive(false)}
          />
        )}
      </>
    </ReportContextProvider>
  );
};

export default SaveReport;
