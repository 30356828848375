import React from 'react';
import DeviceServiceForm from 'components/v2/DeviceServiceForm/DeviceServiceForm';
import { Dir } from 'modules/dirs';
import { useModal } from '../../../../components/v2/Modal/useModal';
import { useTranslation } from 'utils/translation';
import { useFeedbackItemInfo, useSubmitFeedback } from '../hooks';
import { Device, RepairRequestValues } from '../type';
import ServiceRequestForm from './ServiceRequestForm';
import Loader from 'components/v2/Loader';
import Section from 'components/v2/Section/Section';

type Props = {
  id: Dir['id'];
  onSuccess?(): void;
};

const RepairRequestForm = ({ id, onSuccess }: Props) => {
  const { t } = useTranslation();
  const mutate = useSubmitFeedback(id);
  const { data = null, isLoading } = useFeedbackItemInfo(id);

  const onSubmit = async (values: RepairRequestValues) => {
    await mutate.mutateAsync({
      ...values,
      title: t('maintenance_request'),
      files: values.attachments,
      comment: values.issueDescription,
      type: values.issueType,
    });

    onSuccess?.();
  };

  if (isLoading) {
    return (
      <Section>
        <Loader />
      </Section>
    );
  }

  return (
    <DeviceServiceForm attributes={data!.fields} image={data?.cover}>
      <ServiceRequestForm
        action={onSubmit}
        enableAttachments
        enableDescription
        issueTypes={data?.commonIssues}
      />
    </DeviceServiceForm>
  );
};

export const useRepairRequest = (id: Device['id']) => {
  const modal = useModal();

  return () => {
    const Component = () => <RepairRequestForm id={id} onSuccess={onClose} />;
    const onClose = () => modal.close(Component);

    modal.open(Component, { onClose });
  };
};

export default RepairRequestForm;
