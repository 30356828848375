import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Field, FieldUpdatePayload } from '..';
import FluentForm from 'components/v2/Form/FluentForm';
import Form from 'components/v2/Form/Form';
import { useQuery } from '@tanstack/react-query';
import { FieldType } from '../../../components/v2/Form/types';
import { useTranslation } from 'utils/translation';
import { Dir } from '../../dirs';
import { patchUpdateDir, readDir } from '../../dirs/service/api';
import { mapDirectoryTranslatedTitle } from '../../dirs/utils';
import ConditionalFieldCheckerModern from '../components/ConditionalFieldCheckerModern';
import {
  fetchIntelligentFields,
  updateIntelligentFields,
} from '../service/api';
import Loader from 'components/v2/Loader';
import Section from 'components/v2/Section/Section';
import Spacer from 'components/v2/Spacer/Spacer';
import { groupBy } from 'lodash';
import { useAppSettings } from '../../ui/hooks';
import Tabs from '../../../components/v2/Tabs/Tabs';
import Typography from '../../../components/v2/Typography/Typography';
import {
  mapFieldToProps,
  mapPayloadToValues,
  mapValuesToPayload,
} from '../utils';

export type Props = {
  identifier: string;
  enableTitle?: boolean;
  resolver(): Promise<[any, Field[]]>;
  persister(values: FieldUpdatePayload & { title?: any }): Promise<void>;
  onSuccess?(): void;
};

const SingleField = ({ field }: { field: Field }) => {
  const { getValues } = useFormContext();
  const values = mapValuesToPayload(getValues());

  return (
    <ConditionalFieldCheckerModern
      condition={field.settings?.condition}
      values={values}
    >
      <Form.Field {...mapFieldToProps(field)} />
    </ConditionalFieldCheckerModern>
  );
};

export const BasicFieldsForm = ({
  fields,
  persister,
  onSuccess,
  defaultValues,
  enableTitle = false,
  ...rest
}: {
  fields: Field[];
} & Pick<Props, 'persister' | 'onSuccess' | 'enableTitle'> &
  Pick<
    React.ComponentProps<typeof Form>,
    'defaultValues' | 'onChange' | 'errors'
  >) => {
  const {
    settings: { locales },
  } = useAppSettings();
  const { t } = useTranslation();
  const groups = groupBy(fields, (item) => item.group?.name.toLowerCase());
  const tabs = Object.keys(groups);
  const localeTabs = locales.filter((item: string) => tabs.includes(item));

  return (
    <Section>
      <FluentForm
        {...rest}
        action={persister}
        onSuccess={onSuccess}
        defaultValues={{
          ...defaultValues,
          ...mapPayloadToValues(fields),
        }}
      >
        <Tabs fullWidth>
          <Tabs.List>
            {localeTabs.map((locale: string) => (
              <Tabs.Tab key={locale}>{locale}</Tabs.Tab>
            ))}
          </Tabs.List>
          {localeTabs.map((locale: string) => (
            <Tabs.Panel key={locale}>
              {enableTitle && (
                <Form.Field
                  rules={{ required: true }}
                  name={`title.${locale}`}
                  type={FieldType.Text}
                  label={t('Title')}
                />
              )}
              {groups[locale]!.map((item) => (
                <React.Fragment key={item.id}>
                  {item.settings?.title && (
                    <Typography variant="h2">{item.settings.title}</Typography>
                  )}
                  <SingleField field={item} />
                </React.Fragment>
              ))}
            </Tabs.Panel>
          ))}
        </Tabs>
      </FluentForm>
    </Section>
  );
};

const IntelligenceFieldsForm = ({
  identifier,
  resolver,
  persister,
  onSuccess,
  enableTitle = false,
}: Props) => {
  const { data = [], isLoading } = useQuery({
    queryFn: resolver,
    queryKey: [identifier],
    gcTime: 0,
    retry: false,
  });

  if (isLoading) {
    return (
      <Section>
        <Spacer />
        <Loader />
        <Spacer />
      </Section>
    );
  }

  const [title = {}, fields = []] = data;

  return (
    <BasicFieldsForm
      enableTitle={enableTitle}
      defaultValues={{
        title,
      }}
      fields={fields}
      persister={persister}
      onSuccess={onSuccess}
    />
  );
};

export const DirectoryIntelligenceFieldsForm = ({
  id,
  onSuccess,
  enableTitle = false,
}: Pick<Dir, 'id'> & Pick<Props, 'onSuccess' | 'enableTitle'>) => {
  const {
    settings: { locales },
  } = useAppSettings();

  return (
    <IntelligenceFieldsForm
      enableTitle={enableTitle}
      identifier={`intelligence_values_of_directory_${id}`}
      resolver={() =>
        Promise.all([
          readDir({ id }).then((dir) =>
            mapDirectoryTranslatedTitle(dir, locales),
          ),
          fetchIntelligentFields({ id }),
        ])
      }
      persister={async ({ title, ...values }) => {
        if (enableTitle) {
          await patchUpdateDir({ id, title });
        }

        await updateIntelligentFields({
          dirId: id,
          fields: mapValuesToPayload(values),
        });
      }}
      onSuccess={onSuccess}
    />
  );
};
