import {
  flexRender,
  Table as TableType,
  Cell,
  Row,
} from '@tanstack/react-table';
import { RowData } from '@tanstack/table-core';
import React from 'react';
import Table from '../Table/Table';

type RenderCellArgs<TData extends RowData> = {
  cell: Cell<TData, unknown>;
  row: Row<TData>;
  index: number;
};

type Props<TData extends RowData> = {
  table: TableType<TData>;
  pagination?: React.ReactNode;
  actions?: React.ReactNode;
  slotProps?: {
    root?: {
      hideHeaders?: boolean;
    };
    table?: React.ComponentProps<typeof Table>;
  };
  renderCell?({ cell, row, index }: RenderCellArgs<TData>): React.ReactNode;
};

export function defaultRenderCell<TData extends RowData>({
  cell,
}: RenderCellArgs<TData>) {
  return flexRender(cell.column.columnDef.cell, cell.getContext());
}

function DefaultTable<TData extends RowData>(props: Props<TData>) {
  const {
    table,
    actions,
    pagination,
    slotProps: {
      root: { hideHeaders = false } = {},
      table: tableProps = {},
    } = {},
    renderCell = defaultRenderCell,
  } = props;

  return (
    <Table.Wrapper>
      {actions && <Table.Actions>{actions}</Table.Actions>}
      <Table {...tableProps}>
        {!hideHeaders && (
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.Cell
                    variant="head"
                    key={header.id}
                    align={header.column.columnDef.meta?.align}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>
        )}
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <Table.Cell
                  key={cell.id}
                  align={cell.column.columnDef.meta?.align}
                >
                  {renderCell({ cell, row, index })}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
        {pagination && (
          <Table.Foot>
            <Table.Row>{pagination}</Table.Row>
          </Table.Foot>
        )}
      </Table>
    </Table.Wrapper>
  );
}

export default DefaultTable;
