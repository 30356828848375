import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Values } from '../../../modules/reports/types';
import { empty } from '../../../utils/array';
import string from '../../../utils/string';
import { useFiltersQueryParams } from '../../../utils/use-data-params';

type BasicValue = string | number | boolean | unknown | BasicValue[];

export type BaseValues = {
  [key: string]: BasicValue;
};

type FiltersContextType<Values extends object = BaseValues> = {
  values: Values;
  setValues(values: Values): void;
  remove(key: keyof Values): void;
  setValue(key: keyof Values, value: BasicValue): void;
  committedValues: Values;
  commitValues(values: Values): void;
  getActiveFilters(): string[];
  hasActiveFilters(): boolean;
};

const FiltersContext = createContext<FiltersContextType>({
  values: {},
  setValues(values: Values) {
    throw new Error('FiltersContextProvider not provided.');
  },
  setValue(key: keyof Values, value: BasicValue) {
    throw new Error('FiltersContextProvider not provided.');
  },
  remove(key: keyof Values) {},
  committedValues: {},
  commitValues() {},
  getActiveFilters() {
    return [];
  },
  hasActiveFilters() {
    return false;
  },
});

export const useFiltersContext = () => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error(
      'FiltersContext is null. Did you forget to use FiltersContextProvider?',
    );
  }

  return context;
};

export const FiltersContextProvider = ({
  children,
  defaultValues = {},
}: PropsWithChildren<{ defaultValues?: Values }>) => {
  const [params, setQueryParams] = useFiltersQueryParams(defaultValues);
  const [values, setValues] = useState<Values>(params);

  const commitValues = (values: Values) => {
    setQueryParams(values);
  };

  const getActiveFilters = () => {
    return Object.keys(params).filter((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        return !empty(value);
      }

      return Boolean(value);
    });
  };

  const contextValue = useMemo<FiltersContextType>(
    () => ({
      values,
      setValues,
      setValue(key: keyof Values, value: BasicValue) {
        setValues((prev) => ({
          ...prev,
          [key]: value,
        }));
      },
      remove(key) {
        setValues((prev) => {
          const { [key]: value, ...next } = prev;

          return next;
        });
      },
      committedValues: params,
      commitValues,
      getActiveFilters,
      hasActiveFilters(): boolean {
        return !empty(getActiveFilters());
      },
    }),
    [values, setValues, params],
  );

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  );
};
