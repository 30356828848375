import React from 'react';
import { AdapterProps, SelectAdapterProps } from '../types';
import Autocomplete from '../../Autocomplete/Autocomplete';
import InputLabel from '../../InputLabel/InputLabel';
import css from '../filters.module.scss';
import Button from 'components/v2/Button/Button';
import Icon from '../../Icon/Icon';
import { useReportContext } from '../../../../modules/reports/context/ReportContext';
import useSelectAdapterOptionsQuery from '../../../../modules/reports/hooks/use-select-adapter-options-query';

type Value = {
  id: string;
  label: string;
};

const SelectAdapter = (props: AdapterProps<Value> & SelectAdapterProps) => {
  const { name, label, onChange, onRemove, settings, value = [] } = props;
  const { optionsUrl = null, dependencies = [] } = settings;
  const report = useReportContext();

  const query = useSelectAdapterOptionsQuery({
    id: report.id,
    name,
    url: optionsUrl,
    dependencies,
  });

  const options = Array.from(query.data || []);

  const mapValueToSelectedOptions = () => {
    if (!query.data) {
      return [];
    }

    return query.data.filter(
      (item) => value && value.map((i) => String(i)).includes(item.id),
    );
  };

  return (
    <div className={css.filter}>
      <InputLabel className={css.label}>{label}</InputLabel>
      <Autocomplete
        border={false}
        modifier={'filter'}
        clearText="Filters wissen"
        multiple
        options={options}
        value={mapValueToSelectedOptions()}
        onChange={(event, value) => {
          onChange!(value.map((i) => i.id));
        }}
        getOptionKey={(option) => option.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      {onRemove && (
        <Button
          tabIndex={-1}
          onClick={onRemove}
          variant="text"
          startIcon={<Icon name="fa fa-times" />}
          className={css['remove-button']}
        />
      )}
    </div>
  );
};

export default SelectAdapter;
