import { isAdmin, isEmployee, isOneOf, isSuperAdmin, isUser } from './utils';

const voters = {
  // Files
  'view files': isOneOf(isUser, isAdmin, isEmployee, isSuperAdmin),
  'create file': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'update file': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'delete file': isOneOf(isAdmin, isEmployee, isSuperAdmin),

  // Directories
  'create directory': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'update directory': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'delete directory': isOneOf(isAdmin, isEmployee, isSuperAdmin),

  // Directory Fields
  'update directory fields': isOneOf(isAdmin, isEmployee, isSuperAdmin),

  // Users
  'view users': isOneOf(isEmployee, isSuperAdmin),
  'create user': isOneOf(isEmployee, isSuperAdmin),
  'update user': isOneOf(isEmployee, isSuperAdmin),
  'delete user': isOneOf(isEmployee, isSuperAdmin),

  // Settings
  'view settings': isOneOf(isEmployee, isSuperAdmin),

  // Assets / Devices
  'view device groups': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'view service requests': isOneOf(isAdmin, isEmployee, isSuperAdmin),
  'view system log': isOneOf(isAdmin, isEmployee, isSuperAdmin),
} as const;

export default voters;
