import React, { forwardRef, HTMLProps, PropsWithChildren } from 'react';
import css from './table.module.scss';
import clsx from 'clsx';
import Icon from '../Icon/Icon';

const TableWrapper: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={css.wrapper} />
);

const TableActions: React.FC<HTMLProps<HTMLDivElement>> = (props) => (
  <div {...props} className={css.actions} />
);

const Table: React.FC<HTMLProps<HTMLTableElement> & { isFixed?: boolean }> = ({
  isFixed = false,
  ...rest
}) => <table {...rest} className={clsx(css.table, isFixed && css.fixed)} />;

const TableRow: React.FC<HTMLProps<HTMLTableRowElement>> = (props) => (
  <tr {...props} className={css.row} />
);

const TableHead: React.FC<HTMLProps<HTMLTableSectionElement>> = (props) => (
  <thead {...props} className={css.head} />
);

const TableBody: React.FC<HTMLProps<HTMLTableSectionElement>> = (props) => (
  <tbody {...props} className={css.body} />
);

const TableFooter: React.FC<HTMLProps<HTMLTableSectionElement>> = (props) => (
  <tfoot {...props} className={css.footer} />
);

const TableCell: React.FC<
  PropsWithChildren & {
    variant?: 'cell' | 'head';
    size?: 'small';
    align?: 'left' | 'right' | 'center';
  }
> = ({ variant = 'cell', size, children, align, ...props }) => {
  const Tag = variant === 'cell' ? 'td' : 'th';

  return (
    <Tag
      {...props}
      className={clsx(
        css.cell,
        size && css[size],
        align && css[`align-${align}`],
      )}
    >
      {children}
    </Tag>
  );
};

const TableCellContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, ...props }, ref) => {
    return (
      <div className={css['cell-content']} {...props} ref={ref}>
        {children}
      </div>
    );
  },
);

const SortAsc = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
    <Icon className={css['sort-icon']} name="fas fa-arrow-down" />
  </span>
);

const SortDesc = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
    <Icon className={css['sort-icon']} name="fas fa-arrow-up" />
  </span>
);

const Sortable = ({
  children,
  ...rest
}: React.PropsWithChildren<HTMLProps<HTMLSpanElement>>) => (
  <span {...rest} className={css.sort}>
    {children}
  </span>
);

export default Object.assign(Table, {
  Wrapper: TableWrapper,
  Actions: TableActions,
  Row: TableRow,
  Head: TableHead,
  Body: TableBody,
  Foot: TableFooter,
  Cell: TableCell,
  CellContent: TableCellContent,
  SortAsc,
  SortDesc,
  Sortable,
});
