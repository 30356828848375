import Loader from 'components/v2/Loader/Loader';
import { useReportItem } from 'modules/reports/hooks/use-report-item';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReportContext } from '../../../../modules/reports/context/ReportContext';
import Link from '../../Link/Link';
import css from './attributes.module.scss';
import { Attribute } from '../../../../modules/reports/types';
import QRCode from 'components/v2/QRCode/QRCode';
import Spacer from 'components/v2/Spacer/Spacer';
import clsx from 'clsx';
import { useTranslation } from 'utils/translation';

const Attributes = ({ attributes }: { attributes: Attribute[] }) => {
  const { t } = useTranslation();

  return (
    <ul className={css.attributes}>
      {attributes.map((item) => (
        <li className={css.item} key={item.name}>
          <span className={css.label}>{item.label}</span>
          <span className={css.value}>
            {item.value || t('attribute.not_available')}
          </span>
        </li>
      ))}
    </ul>
  );
};

type Props = {
  id: number;
};

const DeviceAttributes = ({ id }: Props) => {
  const { data, isLoading } = useReportItem(id);

  if (isLoading) {
    return <Loader />;
  }

  const summary = data!.attributes.filter((item) => item.section === 'summary');
  const rest = data!.attributes.filter((item) => null === item.section);

  return (
    <div className={clsx(css.root, css['variant-default'])}>
      <div className={css.section}>
        <Attributes attributes={summary} />
        <div className={css.image}>
          <QRCode value={data!.identifier} />
        </div>
      </div>
      <div className={css.section}>
        <Attributes attributes={rest} />
      </div>
    </div>
  );
};

export const DeviceAttributesVertical = ({
  id,
  children,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const report = useReportContext();
  const { data, isLoading } = useReportItem(id);
  const navigate = useNavigate();

  const onDetailsClick = () => {
    navigate(`/assets/report/${report.id}/view/${id}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  const summary = data!.attributes.filter((item) => item.section === 'summary');
  const rest = data!.attributes.filter((item) => null === item.section);

  return (
    <div className={clsx(css.root, css['variant-vertical'])}>
      <div className={css.image}>
        <QRCode onClick={onDetailsClick} value={data!.identifier} />
      </div>
      <Spacer size={2} />
      <Link className="link" to={`/assets/report/${report.id}/view/${id}`}>
        {t('assets.detail_page')}
      </Link>
      <Spacer size={4} />
      <div className={css.section}>
        <Attributes attributes={summary} />
      </div>
      <div className={css.section}>
        <Attributes attributes={rest} />
      </div>
      {children}
    </div>
  );
};

export default DeviceAttributes;
