import React from 'react';
import css from './typography.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  variant: React.ComponentType;
  component?: React.ComponentType;
  color?: 'primary' | 'inherit' | 'success' | 'error' | 'warning';
  align?: 'left' | 'center' | 'right';
};

const Typography: React.FC<Props & React.ComponentProps<any>> = (props) => {
  const {
    variant = 'p',
    component: Component = variant,
    color,
    align,
    className,
    children,
  } = props;

  return (
    <Component
      className={clsx(
        className,
        css.root,
        variant && css[variant],
        color && css[`color-${color}`],
        align && css[`align-${align}`],
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;
