import { Field } from './index';
import { FieldType, FormField } from '../../components/v2/Form/types';

const mapFieldTypeToFormType = (type: string): FieldType => {
  if (type === 'select') {
    return FieldType.Autocomplete;
  }

  return type as FieldType;
};

export function mapFieldToProps(field: Field): FormField {
  const { name, multiple, settings } = field;

  const shared = {
    type: mapFieldTypeToFormType(field.type),
    name,
    label: settings?.label,
    rules: {
      required: field.settings?.required,
    },
  };

  if (['select', 'select-creatable'].includes(field.type)) {
    // @ts-ignore
    return {
      ...shared,
      multiple,
      options: field.settings.options || [],
    };
  }

  return shared as FormField;
}

export function mapSelectValue(field: Field) {
  const values = field.settings?.options?.filter((item) => {
    if (Array.isArray(field.value)) {
      return field.value.includes(item.value);
    }

    return item.value === field.value;
  });

  if (!values) {
    return field.multiple ? [] : null;
  }

  return field.multiple ? values : values[0];
}

export function mapPayloadToValues(data: Field[]) {
  return data.reduce(
    (prev, current: Field) => {
      const type = current.type;
      const transformers = {
        select: mapSelectValue,
        ['select-creatable']: mapSelectValue,
      };
      const transformer = transformers[type as keyof typeof transformers];
      const value = transformer ? transformer(current) : current.value;

      if (value) {
        prev[current.name] = value;
      }

      return prev;
    },
    {} as {
      [key: string]: any;
    },
  );
}

function extractValue(value: any) {
  if (value && typeof value === 'object' && value.hasOwnProperty('value')) {
    return value.value;
  }

  return value;
}

export function mapValuesToPayload(values: any) {
  const entries = Object.keys(values).map((key) => {
    let value;

    if (values[key] && Array.isArray(values[key])) {
      value = values[key].map(extractValue);
    } else {
      value = extractValue(values[key]);
    }

    return [key, value];
  });

  return Object.fromEntries(entries);
}
